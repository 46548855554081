import { render, staticRenderFns } from "./Stage.vue?vue&type=template&id=52838b26&scoped=true&"
import script from "./Stage.vue?vue&type=script&lang=js&"
export * from "./Stage.vue?vue&type=script&lang=js&"
import style0 from "./Stage.vue?vue&type=style&index=0&id=52838b26&prod&lang=postcss&scoped=true&"
import style1 from "./Stage.vue?vue&type=style&index=1&id=52838b26&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52838b26",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/builds/reifen_gundlach/frontend/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default,Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default})
